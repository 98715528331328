<template>
  <div class="BigBackground">
    <Head />
    <div class="banner">
      <img src="../assets/policyMatching/1.png" alt="" />
      <a-space direction="vertical">
        <a-input-search
          class="bannerInput"
          v-model:value="value"
          placeholder="请输入企业名称"
          enter-button="智能匹配"
          size="large"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div class="bar">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>高分企业</a-breadcrumb-item>
      </a-breadcrumb>
      <!-- <div class="center">
        <a-row>
          <a-col :span="6">
            <div class="left">
              <div class="leftTitle1">高新技术企业奖励</div>
              <div class="leftTitle2">
                <div><img src="../assets/Notice/4.png" alt="" /></div>
                <div class="lefttitle3">自由贸易试验区沈阳片区管委会</div>
              </div>
              <div class="leftTitle2">
                <div><img src="../assets/Notice/5.png" alt="" /></div>
                <div class="lefttitle3">结束倒计时   <span class="day">25天</span>  </div>
              </div>
            </div>
          </a-col>
          <a-col :span="18" class="eRight">
           
          </a-col>
        </a-row>
      </div> -->
    </div>
  </div>
</template>
<script>
import Head from "../components/Head";

export default {
  //首页
  name: "Unscramble",
  data() {
    return {
      value:""

    };
  },
  components: {
    Head,
  },
  methods:{
      onSearch(){
          console.log(this.value);
      }
  }
};
</script>
<style lang="less" scoped>
.bar {
  width: 1440px;
  margin: 0 auto;
}
.banner {
  position: relative;
}
.bannerInput {
  position: absolute;
  left: 720px;
  top: 50px;
  width: 500px;
  height: 400px;
}
.center {
  margin-top: 22px;
}

.leftTitle1 {
  color: #0060dd;
  font-weight: bold;
  font-size: 22px;
  margin: 40px 0 28px 28px;
}
.leftTitle2 {
  color: #333;
  font-size: 14px;
  margin-left: 28px;
  display: flex;
  img {
    margin-right: 10px;
  }
}
.leftTitle3 {
  margin-top: 3px;
}

.BigBackground {
  background: #fafafa;
  height: 100vh;
}
.left {
  background: url(../assets/Notice/3.png) no-repeat;
  height: 500px;
  overflow: hidden;
}
.eRight {
  // border-top: 2px solid red;
  background: #fff;
  height: 800px;
}
.day{
  color: #0060dd;
}
</style>