<template>
  <div class="PolicyMatching">
    <Head />
    <div class="banner">
      <img src="../assets/policyMatching/1.png" alt="" />
      <a-space direction="vertical">
        <a-input-search
          class="bannerInput"
          v-model:value="value"
          placeholder="请输入关键词相关成果"
          enter-button="智能匹配"
          size="large"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div class="center">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>政策匹配</a-breadcrumb-item>
      </a-breadcrumb>
      <a-row> 
        <a-col
          class="centerList"
          :span="11"
          v-for="item of productList"
          :key="item"
        >
          <div class="centerLists">
            <div class="daStarts">
              <div class="title">{{ item.title }}</div>
              <div class="stars">
                <img src="../assets/policyMatching/4.png" alt="" />
              </div>
            </div>
            
            <div class="title1">
              <span class="titleSpan">信息来源：</span>{{ item.title1 }}
            </div>
            <div class="title1">
              <span class="titleSpan">申报时间：</span>{{ item.title2 }}
            </div>
            <div class="title1">
              <span class="titleSpan">信息地区：</span>{{ item.title3 }}
            </div>
            
            <div class="title1">
              <span class="titleSpan">支持力度：</span>{{ item.title4 }}
            </div>
            <div>
               <div>
                 
    <a-button type="primary" @click="showModal">我要申报</a-button>
    <a-modal v-model:visible="visible" title="我要申报" @ok="handleOk">
        <div>公司名称</div>
                  <div>
                    <a-input
                      v-model:value="value"
                      placeholder="请输入公司名称"
                    />
                  </div>
                  <div>所在城市</div>
                  <div>
                    <a-input
                      v-model:value="value"
                      placeholder="请输入所在城市"
                    />
                  </div>
                  <div>联系人</div>
                  <div>
                    <a-input
                      v-model:value="value"
                      placeholder="请输入联系姓名"
                    />
                  </div>
                  <div>联系方式</div>
                  <div>
                    <a-input
                      v-model:value="value"
                      placeholder="请输入联系方式"
                    />
                  </div>
    </a-modal>
  </div>
            </div>
          </div>
        </a-col>
      </a-row>
    </div>

    <div class="centerImg" v-if="productList.length === 0">
      <img src="../assets/policyMatching/2.png" alt="" />
      <p>请在上方搜索栏输入关键字搜索吧</p>
    </div>
    <div class="fenye" v-if="productList.length !== 0">
      <a-pagination v-model:current="current" :total="500" show-less-items />
    </div>

    <CustomerService />
  </div>
</template>
<script>
import Head from "../components/Head";
import CustomerService from "../components/CustomerService";
import { PolicyMatchings } from "../../data/db";

export default {
  //政策查询
  name: "PolicyMatching",
  data() {
    return {
      current:"",
      PolicyMatchings,
      productList: [],
      visible: false,
    };
  },

  components: {
    Head,
    CustomerService,
  },
  methods: {
    onSearch() {
      let arr2 = PolicyMatchings.filter((value) => {
        return value.title.includes(this.value);
      });
      this.productList = arr2;
    },
      showModal(a) {
      this.visible = true

      this.shipin = a
      console.log('abcccccc', this.shipin)
    },
    handleOk(e) {
      this.visible = false
    },
    
  },
};
</script>
<style lang="less" scoped>
.PolicyMatching {
  width: 100%;
  height: 100vh;
  background: #fafafa;
}
.banner {
  position: relative;
}
.bannerInput {
  position: absolute;
  left: 720px;
  top: 50px;
  width: 500px;
  height: 400px;
}
.center {
  width: 1440px;
  margin: 0 auto;
}
.centerImg {
  text-align: center;
  margin: 0 auto;
  margin-top: 100px;

  img {

    width: 280px;
    height: 215px;
  }
}
.centerList {
  width: 693px;
  height: 240px;
  background: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 10px;
  margin: 10px;
}
.title {
  color: #0060dd;
  font-size: 17px;
  font-weight: bold;
}
.title1 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.titleSpan {
  font-size: 14px;
  color: #b3b3b3;
}
.centerLists {
  margin-left: 10px;
}
.listButton {
  margin-top: 20px;
}
.ant-btn-primary {
  background: #1ed7bc;
  border-color: #1ed7bc;
  border-radius: 5px;
  margin-top: 24px;
  margin-bottom: 27px;
  margin-left: 20px;
}
.fenye {
  width: 1440px;
  margin: 0 auto;
}
.daStarts {
  display: flex;
  justify-content: space-between;
  margin-top: 23px;
  margin-bottom: 27px;
}
.stars {
  margin-right: 29px;
}

 .ant-modal-header{
     background: red;
 }
</style>