<template>
  <div class="matching">
    <div class="matchingCenter">
        <img src="../assets/shouye/9.png" alt="" />
    </div>
  </div>
</template>
<script>

export default {
  name: "App",
  data() {
    return {
    };
  },
  components: {},
};
</script>

<style lang="less" scoped>
.matching {
  width: 100%;
  margin-bottom: 20px;
}
.matchingCenter {
  width: 1440px;
  margin: 0 auto;
}




</style>
