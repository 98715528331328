<template>
  <div class="banner">
    <div class="bannerTitle">
      <img src="../assets/shouye/banner.png" alt="" />
      <div class="bannerCenter">
        <div class="bannerTitle1">助力企业科技创新</div>
        <div class="bannerTitle2">科技创新协同服务平台</div>
        <a-space direction="vertical">
          <a-input-search
            class="bannerTitle3"
            v-model:value="value"
            placeholder="input search text"
            enter-button="Search"
            size="large"
            @search="onSearch"
          />
        </a-space>
      </div>
      
      <div class="bannerBottom">
        <div class="bannerBottomImg" v-for="item of banner" :key="item">
          <img :src="item.url" alt="" />
          <div class="bannerBottomTitle1">{{ item.title }}</div>
          <router-link :to="item.path"> <div class="bannerBottomTitle2">查看详情</div></router-link>
          
        </div>
      </div>

      <div class="bannerRight">
        <div class="bannerRight1" v-for="item of bannerCenter" :key="item">
          <p class="bannerp1"> {{item.title}}</p>
          <p class="bannerp2"> {{item.title1}}</p>
        </div>
       
      </div>
    </div>
  </div>
</template>
<script>
import { banner } from "../../data/db";
import { bannerCenter } from "../../data/db";


export default {
  //   props: ["data", "title"],

  name: "App",
  data() {
    return {
      banner,
      bannerCenter
    };
  },
  components: {},
};
</script>

<style lang="less" scoped>
.banner {
  margin-bottom: 10px;
  img {
    width: 100%;
    height: 458px; 
  }
}
.bannerTitle {
  position: relative;
}
.bannerTitle1 {
  //   background: red;
  // position: absolute;
  // left: 800px;
  // top: 189px;
  font-size: 33px;
  font-weight: bold;
  color: #ffffff;
  text-align: center;
}
.bannerTitle2 {
  //   background: red;
  // position: absolute;
  // left: 800px;
  // top: 240px;
  text-align: center;
  font-size: 19px;
  font-weight: 400;
  color: #ffffff;
}
.bannerCenter {
  width: 500px;
  height: 400px;
  // background: red;
  position: absolute;
  left: 700px;
  top: 150px;
}
.bannerTitle3 {
  margin-left: 80px;
  margin-top: 20px;
}
.bannerBottom {
  display: flex;
  width: 1440px;
  margin: 0 auto;
  margin-top: -75px;
}
.bannerBottom {
  img {
    margin-right: 25px;
    width: 330px;
    height: 142px;
  }
}
.bannerBottomImg {
  position: relative;
}
.bannerBottomTitle1 {
  position: absolute;
  left: 35px;
  top: 44px;
  font-size: 24px;
  font-weight: bold;
  color: #ffffff;
}
.bannerBottomTitle2 {
  padding: 1px 8px;
  position: absolute;
  left: 35px;
  top: 85px;
  font-weight: bold;
  color: #ffffff;
  border: 1px solid #ffffff;
  border-radius: 6px;
}
.bannerRight {
    background: #FAFAFA;
    border-radius: 10px;

//   background: red;
  width: 234px;
  height: 258px;
  position: absolute;
  left: 1332px;
  top: 0px;
}
.bannerRight1{
  padding-bottom: 10px;
  margin-left: 19px;
  margin-top: 10px;
    border-bottom: 1px solid #E5E5E5;
    p{
        margin: 0;
        padding: 0;
    }
}
.bannerp1{
font-size: 12px;
font-weight: 400;
color: #0060DD;

}
.bannerp1{
font-size: 12px;
font-weight: 400;

}
</style>
