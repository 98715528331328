<template>
  <div class="PolicyMatching">
    <Head />
    <div class="banner">
      <img src="../assets/policyMatching/1.png" alt="" />
      <a-space direction="vertical">
        <a-input-search
          class="bannerInput"
          v-model:value="value"
          placeholder="请输入关键词相关成果"
          enter-button="智能匹配"
          size="large"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div class="center">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>政策搜索</a-breadcrumb-item>
      </a-breadcrumb>
      <a-tabs tabBarGutter="50" v-model:activeKey="activeKey">
        <a-tab-pane key="1" tab="宏观政策">
          <PolicySearch :content="PolicySearch3" title="政策级别"></PolicySearch>
          <PolicySearch :content="PolicySearch2" title="政策类型"></PolicySearch>
          <PolicySearch :content="PolicySearch1" title="行业类型"></PolicySearch>
             <PolicyForms :content="PolicyFormsContent" :contentTitle="PolicyFormsContentTitle"></PolicyForms>
        </a-tab-pane>
        <a-tab-pane key="2" tab="地区政策" force-render
          >Content of Tab Pane 2</a-tab-pane
        >
        <a-tab-pane key="3" tab="申报通知">Content of Tab Pane 3</a-tab-pane>
        <a-tab-pane key="4" tab="公示公告">Content of Tab Pane 4</a-tab-pane>
      </a-tabs>
   

      <!-- <div></div> -->
    </div>
    <CustomerService />
  </div>
</template>
<script>
import Head from "../components/Head";
import PolicyForms from "../components/PolicyForms";

import CustomerService from "../components/CustomerService";
import PolicySearch from "../components/PolicySearch";

import { PolicySearch3, PolicySearch2, PolicySearch1 } from "../../data/db";

import { PolicyMatchings,PolicyFormsContent, PolicyFormsContentTitle } from "../../data/db";

//
export default {
  //政策新闻
  name: "PolicyMatching",
  data() {
    return {
      PolicyFormsContent,
      PolicyMatchings,
      PolicySearch3,
      PolicySearch2,
      PolicySearch1,
      PolicyFormsContentTitle
    };
  },

  components: {
    //   list:[],
    Head,
    CustomerService,
    PolicySearch,
    PolicyForms
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.PolicyMatching {
  width: 100%;
  height: 100vh;
  background: #fafafa;
}
.banner {
  position: relative;
}
.bannerInput {
  position: absolute;
  left: 720px;
  top: 50px;
  width: 500px;
  height: 400px;
}
.center {
  width: 1440px;
  margin: 0 auto;
}

.ant-btn-primary {
  background: #1ed7bc;
  border-color: #1ed7bc;
}
</style>