<template>
  <a-table :columnWidth="300" :dataSource="content" :columns="contentTitle"  :row-class-name="(_record, index) => (index % 2 === 1 ? 'table-striped' : null)"/>
</template>
<script>
export default {
  props: ["content", "contentTitle"],

  //政策查询
  name: "PolicyMatching",
  data() {
    return {};
  },

  components: {},
  methods: {
    onSearch() {
      console.log("adadad");
    },
  },
};
</script>
<style lang="less" scoped>
/deep/.ant-table-thead > tr > th {
  background: #0060dd;
  color: #fff;
}
/deep/ .ant-table-container table > thead > tr:nth-child(1) th:nth-child(1) {
  width: 300px;
}

/deep/.ant-table-container table > thead > tr:first-child th:last-child {
  width: 500px;
}
/deep/ .ant-table-pagination-right {
  justify-content: flex-start;
}
/deep/ .ant-table {
  line-height: 10px;
}
:deep(.table-striped) td {
  background-color: #fafafa;
  // color: red;
}
</style>