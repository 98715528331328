<template>
  <div class="achievements">
    <div class="achievementsCenter">
      <div class="achievementsTitle">
        <img src="../assets/shouye/6.png" alt="" />
        院校成果
      </div>

      <div>
        <a-row>
          <a-col :span="15" class="achievementsLeft">
            <div class="achievementsDaTitle">热门成果</div>
            <a-row>
              <a-col :span="12" v-for="item of remenAchievements" :key="item">
                <div class="school">
                  <div class="schoolImg">
                    <img :src="item.url" alt="" />
                  </div>
                  <div class="schoolRight">
                    <div class="schoolTitle1">
                      {{ item.title }}
                    </div>
                    <div class="schoolTitle2">
                      {{ item.title1 }}
                    </div>
                    <div class="schoolTitle3">
                      {{ item.school }}
                      <span class="schoolTitleSpan">{{item.time}}</span>
                    </div>
                  </div>
                </div>
              </a-col>
             
            </a-row>
          </a-col>

          <a-col :span="8" class="achievementsRight">
            <div class="achievementsDaTitle">院校成果</div>
            <div
              class="customerImgs"
              v-for="item of xuexiaoAchievements"
              :key="item"
            >
              {{ item.title }}
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { xuexiaoAchievements } from "../../data/db";
import { remenAchievements } from "../../data/db";

export default {
  name: "App",
  data() {
    return {
      xuexiaoAchievements,
      remenAchievements,
    };
  },
  components: {},
};
</script>

<style lang="less" scoped>
.achievements {
  width: 100%;
  margin-bottom: 20px;
}
.achievementsCenter {
  width: 1440px;
  margin: 0 auto;
}
.achievementsTitle {
  font-size: 16px;
  font-weight: bold;
  color: #0060dd;
  margin-bottom: 10px;
  img {
    width: 23px;
    height: 24px;
  }
}
.school {
  display: flex;
}
.schoolImg {
  width: 98px;
  height: 98px;
  border-radius: 4px;
  border: 1px solid #000;
  margin: 15px 36px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 12px;
  img {
    vertical-align: middle;
    border-style: none;
  }
}
.schoolRight {
  margin-left: 3px;
  margin-top: 15px;
}
.schoolTitle1 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
}
.schoolTitle2 {
  font-size: 12px;
  font-weight: 400;
  color: #999999;
}
.schoolTitle3 {
  margin-top: 15px;
  font-size: 14px;
  font-weight: 400;
  color: #999999;
}
.schoolTitleSpan {
  padding-left: 5px;
}
.customerImgs{
  font-size: 14px;
font-weight: 400;
color: #333333;
margin-top: 20px;




}
.achievementsRight{
  margin-left: 60px;
}
.achievementsDaTitle{
  font-size: 14px;
font-weight: bold;
color: #333333;

}
</style>
