 //引入VueRouter
 import { createRouter, createWebHistory } from 'vue-router'
//  //引入Luyou 组件
//  import About from '../components/About'
//  import App from '../App'
import Home from '../pages/Home.vue'
import PolicyMatching from '../pages/PolicyMatching.vue'
import PolicyNewList from '../pages/PolicyNewList.vue'
import Login from '../pages/Login.vue'
import Register from '../pages/Register.vue'
import Notice from '../pages/Notice.vue'
import Unscramble from '../pages/Unscramble.vue'
import Achievements from '../pages/Achievements.vue'
import AchieveDetitle from '../pages/AchieveDetitle.vue'
import PolicyList from '../pages/PolicyList.vue'
import Enterprise from '../pages/Enterprise.vue'

 
 //创建router实例对象，去管理一组一组的路由规则
 const router = createRouter({
    history: createWebHistory(),
     routes:[
        { path: '/', component: Home },
        { path: '/PolicyMatching', component: PolicyMatching },
        { path: '/PolicyNewList', component: PolicyNewList },
        { path: '/Login', component: Login },
        { path: '/Register', component: Register },
        { path: '/Notice', component: Notice },
        { path: '/Unscramble', component: Unscramble },
        { path: '/AchieveDetitle', component: AchieveDetitle },
        { path: '/Achievements', component: Achievements },
        { path: '/PolicyList', component: PolicyList },
        { path: '/Enterprise', component: Enterprise },


    ]
 })
 
 //暴露router
 export default router
