<template>
  <div class="PolicyMatching">
    <Head />
    <div class="banner">
      <img src="../assets/policyMatching/1.png" alt="" />
      <a-space direction="vertical">
        <a-input-search
          class="bannerInput"
          v-model:value="value"
          placeholder="请输入关键词相关成果"
          enter-button="智能匹配"
          size="large"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div class="center">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>政策匹配</a-breadcrumb-item>
      </a-breadcrumb>
     
    </div>

   
    

    <CustomerService />
  </div>
</template>
<script>
import Head from "../components/Head";
import CustomerService from "../components/CustomerService";


export default {
  //政策查询
  name: "PolicyMatching",
  data() {
    return {
    
    };
  },

  components: {
    Head,
    CustomerService,
  },
  methods: {
    
    
  },
};
</script>
<style lang="less" scoped>
.PolicyMatching {
  width: 100%;
  height: 100vh;
  background: #fafafa;
}
.banner {
  position: relative;
}
.bannerInput {
  position: absolute;
  left: 720px;
  top: 50px;
  width: 500px;
  height: 400px;
}
.center {
  width: 1440px;
  margin: 0 auto;
}


</style>