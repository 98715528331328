<template>
  <div class="customer">
    <div class="customerCenter">
      <div class="customerTitle">
        <img src="../assets/shouye/6.png" alt="" />
        服务专家
      </div>
      <div class="customerImg">
        <div class="customerImgs" v-for="item of expert" :key="item">
          <img :src="item.url" alt="" />
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { expert } from "../../data/db";

export default {
  name: "App",
  data() {
    return {
      expert,
    };
  },
  components: {},
};
</script>

<style lang="less" scoped>
.customer {
  width: 100%;
  margin-bottom: 20px;
  /* background: #343434; */
}
.customerCenter {
//   height: 219px;
  width: 1440px;
  margin: 0 auto;
}
.customerImg {
  display: flex;
}
.customerImgs {
//   width: 100px;
//   height: 100px;
//   border-radius: 4px;
//   margin: 15px 36px;
//   display: flex;
//   justify-content: center;
//   align-items: center;
//   padding: 12px;
  img {
      width: 177px;
      height: 225px;
    // vertical-align: middle;
    // border-style: none;
  }
}
.customerTitle {
  margin-bottom: 15px;
  font-size: 16px;
  font-weight: bold;
  color: #0060dd;
  img {
    width: 23px;
    height: 24px;
  }
}
</style>
