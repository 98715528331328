<template>
  <div class="login">
    <img src="../assets/login/login1.png" alt="" />
    <div class="loginCenter">
      <div class="loginCenterS">
        <div class="loginCenterImg">
          <img src="../assets/login/login2.png" alt="" />
        </div>
        <div class="title">科技创新协同服务平台</div>
        <div class="userName">
          <a-input v-model:value="value" placeholder=" 用户名" />
        </div>
        <div class="passWord">
          <a-input v-model:value="value" placeholder="密码" />
        </div>
        <div class="button">
          <a-button type="primary">立即登陆</a-button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
// import Head from "../components/Head";              
// import { PolicyMatchings } from "../../data/db";
//
export default {
  //登陆
  name: "Logins",
  data() {
    return {
      //   PolicyMatchings,
    };
  },

  methods: {},
  components: {},
};
</script>
<style lang="less" scoped>
.login {
  width: 100%;
  height: 100%;
  background: #005acc;
  display: flex;
  justify-content: space-between;
  img {
    height: 100vh;
    width: 100vw;
    
  }
}
.loginCenter {
  width: 436px;
  height: 100vh;
  background: #0c3bb2;
}
.loginCenterS {
  margin-top: 340px;
}
.loginCenterImg {
  display: flex;
  justify-content: center;
  align-items: center;
  
  img {
    width: 85px;
    height: 65px;
  }
}
.title {
  font-size: 24px;
  color: #f7f7f7;
  font-weight: bold;
  text-align: center;
  margin-top: 12px;
  margin-bottom: 60px;
}
.ant-input {
  width: 325px;
  height: 50px;
  border-radius: 20px;
}
.userName {
  display: flex;
  justify-content: center;
  align-items: center;
}
.passWord {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
  margin-bottom: 30px;
}
.button {
  display: flex;
  justify-content: center;
  align-items: center;
}
.ant-btn-primary {
  width: 324px;
  height: 50px;
  border-radius: 20px;
}
</style>