<template>
  <div class="head">
    <div class="headConter">
      <div class="headTitle">
        <img src="../assets/shouye/15.png" alt="" />
        科技创新协同服务平台
      </div>
      <div class="headButton">
        <a-dropdown>
          <template #overlay>
            <a-menu @click="handleMenuClick">
              <a-menu-item key="1"> 深圳 </a-menu-item>
              <a-menu-item key="2"> 上海 </a-menu-item>
              <a-menu-item key="3"> 北京 </a-menu-item>
            </a-menu>
          </template>
          <a-button> 沈阳 </a-button>
        </a-dropdown>
      </div>

      <!-- <a-menu v-model:selectedKeys="current" mode="horizontal"> -->
              <a-menu  mode="horizontal">
        <a-menu-item v-for="item of rotationCharts" :key="item.id">
          <!-- {{ item.title }} -->
          <router-link :to="item.path"> {{ item.title }} </router-link>
        </a-menu-item>
      </a-menu>
      <router-link to="login">

      <div class="headTitleRight">
        <img src="../assets/shouye/16.png" alt="" />
        个人中心
      </div>
      </router-link>
      <div class="headImg"><img src="../assets/shouye/1.png" alt="" /></div>
    </div>
  </div>
  <div class="demo-dropdown-wrap"></div>
</template>
<script>
import { rotationCharts } from "../../data/db";

export default {
  name: "App",
  data() {
    return {
      rotationCharts,
      // current:""
    };
  },
  components: {},

    //    mounted(){
    // //通过get请求（URL地址参数拼接）去调后台接口，并打印响应信息
    // this.$axios.get('https://api.apiopen.top/getJoke?page=1&count=2&type=video').then(res=>{   //这里使用箭头函数的形式
    //   console.log(res,"响应信息")
    // })
    //    }

}
</script>

<style lang="less" scoped>
.head {
  width: 100%;
  margin: 0 auto;
  background: #0060dd;
}
.headConter {
  width: 1440px;
  margin: 0 auto;
  display: flex;
}
.headTitle {
  color: #fff;
  line-height: 60px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  img {
    margin-top: -7px;
    margin-right: 5px;
  }
}
.headTitleRight {
  color: #fff;
  line-height: 60px;
  padding: 0 10px;
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  margin-left: 10px;
  img {
    width: 30px;
    height: 30px;
  }
}
.headButton {
  margin-top: 15px;
  padding: 0 10px;
}
.headImg {
  line-height: 60px;
  img {
    width: 21px;
    height: 17px;
    margin-left: 10px;
  }
}
.ant-menu {
  background: #0060dd;
  line-height: 60px;
  border: 0;
  border-bottom: 1px solid #0060dd;
  color: #fff;
}
.ant-menu-horizontal > .ant-menu-item::after,
.ant-menu-horizontal > .ant-menu-submenu::after {
  left: 20px;
  border-bottom: 2px solid transparent;
  transition: border-color 0.3s red;
}
</style>
