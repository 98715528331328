<template>
  <div class="PolicySearch">
        <div>
            <ul class="PolicySearchUl">
                <div class="PolicySearchTitle">{{title}}</div>
                <li class="PolicySearchLi"  v-for="item in content" :key="item">{{item.title}} </li>
            </ul>
        </div>
  </div>
</template>
<script>



export default {
      props: ['content','title'],
            // props: ['content', 'bgccolor', 'tfcolor', 'contentTitle', 'name'],


  //政策新闻
  name: "PolicySearch",
  data() {
    return {
       
    };
  },

  components: {
 
  },
  methods: {},
};
</script>
<style lang="less" scoped>
.PolicySearchUl{
    display: flex;
    padding: 0;

}
.PolicySearchLi{
    // color: red;
    list-style: none;
    border-right: 1px solid #999999;
    padding: 0 10px 0 10px;
    color: #3f3f3f;
}
.PolicySearchTitle{
     color: #0060dd;
     font-size: 14px;
     font-weight: bold;
}
/deep/ .ant-tabs-top > .ant-tabs-nav::before, .ant-tabs-bottom > .ant-tabs-nav::before, .ant-tabs-top > div > .ant-tabs-nav::before, .ant-tabs-bottom > div > .ant-tabs-nav::before{
    border-bottom: 1px solid red;
}
</style>