import { createApp ,prototype} from 'vue'
import App from './App.vue'
import Antd from 'ant-design-vue';
import 'ant-design-vue/dist/antd.css';
import router from './router/index'

import axios from 'axios'
// Vue.prototype.$axios = axios
//  axios = prototype.$axios;
const app = createApp(App);
app.use(router);
app.use(Antd).mount('#app');
