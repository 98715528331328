<template>
  
      <div class="topTitle">

    <div class="topTitleLeft">{{ title }}</div>
    <div class="topTitleRight">
        [更多]
    </div>
      </div>
      
    <ul class="listUl" v-for="item of data" :key="item">
      <li class="list" >
        {{ item.title }} 
      </li>
      <span class="listSpan">{{item.time}}</span>
    </ul>
 
</template>
<script>
// import { xuexiaoAchievements } from "../../data/db";

export default {
  props: ["data", "title"],

  name: "App",
  data() {
    return {};
  },
  components: {},
};
</script>

<style lang="less" scoped>
.topTitle{
    display: flex;
    justify-content: space-between;
    margin-bottom: 6px;
    margin-top: 15px;
}
.topTitleLeft{
  font-size: 14px;
font-weight: bold;
color: #333333;
}
.topTitleRight{

  font-size: 14px;
  font-family: Microsoft YaHei;
  font-weight: bold;
  color: #00B8ED;

}
.listUl{
  display: flex;
      justify-content: space-between;
      padding: 0;
      margin-bottom: 8px;

}
.list{
  width: 408px;
  display: block;
    overflow: hidden;
    white-space: nowrap;
    text-overflow:ellipsis;
    font-size: 14px;
font-weight: 400;
color: #333333;

}
.listSpan{
  font-size: 14px;
font-weight: 400;
color: #999999;
}
</style>
