<template>
  <div class="BigBackground">
    <Head />
    <div class="bar">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>政策搜素</a-breadcrumb-item>
        <a-breadcrumb-item>通知公告</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="center">
        <a-row>
          <a-col :span="6">
            <div class="left">
              <div class="leftTitle1">高新技术企业奖励</div>
              <div class="leftTitle2">
                <div><img src="../assets/Notice/4.png" alt="" /></div>
                <div class="lefttitle3">自由贸易试验区沈阳片区管委会</div>
              </div>
              <div class="leftTitle2">
                <div><img src="../assets/Notice/5.png" alt="" /></div>
                <div class="lefttitle3">结束倒计时   <span class="day">25天</span>  </div>
              </div>
            </div>
          </a-col>
          <a-col :span="18" class="uRight">
            <a-tabs v-model:activeKey="activeKey">
              <a-tab-pane key="1" tab="政策简牍 ">
                <div class="rightcenter">
                  <div class="uRightTitle1">产品类别</div>
                  <div>
                    <ul class="rightLi">
                      <li>电子信息</li>
                      <li>5G/移动通信</li>
                      <li>物联网</li>
                      <li>软件</li>
                    </ul>
                  </div>
                  <div>
                    <div class="uRightTitle1">申报条件</div>
                    <div class="uRightTitle3">
                      一是在沈阳自贸试验区注册登记具有独立法人资格的企业。二是新认定的高新技术企业。
                    </div>
                  </div>
                  <div>
                    <div class="uRightTitle1">支持力度</div>
                    <div class="uRightTitle3">
                      对首次通过认定的高新技术企业，给予26万元奖励；对重新认定的高新技术企业给予06万元奖励。
                    </div>
                  </div>
                  <div>
                    <div class="uRightTitle1">申报材料</div>
                    <div>
                      申请材料按如下顺序编制目录、装订成册、编印页码，一式1份。
                    </div>
                    <ul class="rightLi2">
                      <li>
                        1.科技政策奖励补助资金申请书(见附件1)，由企业法定代表人签名、加盖企业公章。
                      </li>
                      <li>
                        2.申请科技政策奖励补助资金承诺书(见附件2)，由企业法定代表人签名、加盖企业公章
                      </li>
                      <li>
                        3.申报奖励补助项目所需材料(见附件9)，每份材料首页和骑缝加盖企业公章.
                      </li>
                      <li>
                        4.企业信息表(见附件7)，无表格内相关信息填-"
                        表格正反面打印，由企业法定代表人签名、加盖企业公章
                      </li>
                      <li>5.企业营业执照副本复印件，加盖企业公章。</li>
                      <li>
                        6.企业经办人身份证复印件(正、反面)，由企业法定作代表人签名、加盖企业公章。
                      </li>
                    </ul>
                  </div>
                  <div>
                    <div class="uRightTitle1"> 项目来源</div>
                    <div>
                      关于兑现2017年度和2018年度科技政策的通知
                      <br />http://ftz.shenyang.gov.cn/html/SYZMQ/
                    </div>
                  </div>
                </div>
              </a-tab-pane>

              <a-tab-pane key="2" tab="政策原文 " force-render
                >Content of Tab Pane 2</a-tab-pane
              >
              <a-tab-pane key="3" tab="资助历史 "
                >Content of Tab Pane 3</a-tab-pane
              >
            </a-tabs>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../components/Head";

export default {
  //首页
  name: "Unscramble",
  components: {
    Head,
  },
};
</script>
<style lang="less" scoped>
.bar {
  width: 1440px;
  margin: 0 auto;
}
.center {
  margin-top: 22px;
}

.leftTitle1 {
  color: #0060dd;
  font-weight: bold;
  font-size: 22px;
  margin: 40px 0 28px 28px;
}
.leftTitle2 {
  color: #333;
  font-size: 14px;
  margin-left: 28px;
  display: flex;
  img {
    margin-right: 10px;
  }
}
.leftTitle3 {
  margin-top: 3px;
}

.BigBackground {
  background: #fafafa;
  height: 100vh;
}
.left {
  background: url(../assets/Notice/3.png) no-repeat;
  height: 500px;
  overflow: hidden;
}
.uRight {
  // border-top: 2px solid red;
  background: #fff;
  height: 800px;
}
.rightcenter {
  margin-left: 30px;
}
.uRightTitle1 {
  font-size: 14px;
  color: #333333;
  font-weight: bold;
  margin-bottom: 14px;
  // margin-top: 0px;
}
.rightLi {
  display: flex;
  li {
    width: 84px;
    height: 34px;
    line-height: 34px;
    background: #fafafa;
    margin-left: 20px;
    list-style: none;
    text-align: center;
    color: #333333;
  }
}
.uRightTitle3 {
  margin-bottom: 33px;
  font-size: 13px;
}
.rightLi2{
  li{
    list-style: none;
  }
}
.ant-tabs-top > .ant-tabs-nav::before{
 border-top: 2px solid #0060dd;
}
.day{
  color: #0060dd;
}
</style>