<template>
  <div class="Register">
    <div class="registerCenter">
      <div class="registerCenterS">
      <div class="title">注册</div>
      <div class="tel">
        <div class="titleP">手机号码</div>
        <div class="userName">
          <a-input v-model:value="value" placeholder="用户名" />
        </div>
      </div>

      <div>
        <div class="titleP">公司名称</div>
        <div class="userName">
          <a-input v-model:value="value" placeholder=" 用户名" />
        </div>
      </div>

       <div class="passWord">
        <div class="titleP">设置密码</div>
        <div class="userName">
          <a-input v-model:value="value" placeholder="用户名" />
        </div>
      </div>

       <div>
        <div class="titleP">重复密码</div>
        <div class="userName">
          <a-input v-model:value="value" placeholder="用户名" />
        </div>
      </div>

       <div>
        <div class="userName">验证码</div>
      </div>
      <!-- <div>
         <div class="code" @click="refreshCode">
    <s-identify :identifyCode="identifyCode"></s-identify>
  </div>
      </div> -->
      <div>
      <div>
      <span>验证码：</span>
      <input
        autocomplete="off"
        type="text"
        id="code"
        v-model="code"
        class="code"
        placeholder="请输入您的验证码"
      />
    </div>
    <div class="login-code" @click="refreshCode">
      <!--验证码组件-->
      <s-identify :identifyCode="identifyCode"></s-identify>
    </div>
    <div>
      <el-button type="primary" @click="showAGV()">提交</el-button>
    </div>
  </div>

       <div class="button">
          <a-button type="primary">立即登陆</a-button>
        </div>
        <Router-link to="login">

         <div class="button">
          <a-button type="primary">返回登陆</a-button>
        </div>
        </Router-link>
        </div>
    </div>
  </div>
</template>
<script>
//

import SIdentify from "../components/Identify";
export default {
  //注册
  name: "Register",
  data() {
    return {
      //PolicyMatchings,
      identifyCodes: "1234567890",
      identifyCode: "",
      code: "", //text框输入的验证码

    };
  },
  mounted() {
    this.identifyCode = "";
    this.makeCode(this.identifyCodes, 4);

  },

  components: {},
  methods: {
   showAGV() {
      if (this.code == "") {
        alert("请输入验证码");
        return;
      }
      if (this.identifyCode !== this.code) {
        this.code = "";
        this.refreshCode();
        alert("请输入正确的验证码!");
        return;
      } else {
        this.$message({
          message: "验证成功",
          type: "success",
        });
      }
    },
    //验证码
    randomNum(min, max) {
      return Math.floor(Math.random() * (max - min) + min);
    },
 
    refreshCode() {
      this.identifyCode = "";
      this.makeCode(this.identifyCodes, 4);
    },
    makeCode(o, l) {
      for (let i = 0; i < l; i++) {
        this.identifyCode +=
          this.identifyCodes[this.randomNum(0, this.identifyCodes.length)];
      }
      console.log(this.identifyCode);
    },


  },
  created() {
    this.refreshCode();
  },

};
</script>
<style lang="less" scoped>
.Register {
  width: 1920px;
  height: 1080px;
  background: #0055c0;
  background: url("../assets/login/login1.png");
  overflow: hidden;
}
.registerCenter {
  width: 445px;
  height: 670px;
  background: #ffffff;
  margin-top: 150px;
  margin-left: 1300px;
    overflow: hidden;

}
.registerCenterS{
  margin-top: 30px;
}
.title{
  font-size: 24px;
  font-weight: bold;
  text-align: center;
}
.ant-input {
  width: 325px;
  height: 50px;
  border-radius: 20px;
  background: #f7f7f7;
}
.userName {
  display: flex;
  justify-content: center;
  align-items: center;
}
.titleP{
  margin-left: 65px;
}
.tel{
  margin-top: 30px;
  margin-bottom: 15px;
}
.passWord{
   margin-top: 15px;
  margin-bottom: 15px;
}
     .button {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 10px;
}
.ant-btn-primary {
  width: 324px;
  height: 50px;
  border-radius: 20px;
}
.code {
  margin: 400px auto;
  width: 114px;
  height: 40px;
  border: 1px solid red;
}
</style>