<template>
  <div class="PolicyMatching">
    <Head />
    <div class="banner">
      <img src="../assets/policyMatching/1.png" alt="" />
      <a-space direction="vertical">
        <a-input-search
          class="bannerInput"
          v-model:value="value"
          placeholder="请输入关键词相关成果"
          enter-button="智能匹配"
          size="large"
          @search="onSearch"
        />
      </a-space>
    </div>
    <div class="bar">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item> 成果列表</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="Acenter">
        <div class="Acenterlist">
          <ul class="AcenterUl">
            <div class="listTitle">成果类型</div>
            <li class="Acenterli" v-for="item in PolicyType" :key="item">
              {{ item.title }}
            </li>
          </ul>
        </div>
        <div class="Acenterlist">
          <ul class="AcenterUl">
            <div class="listTitle">应用领域</div>
            <li class="Acenterli" v-for="item in PolicyField" :key="item">
              {{ item.title }}
            </li>
          </ul>
        </div>

        <div class="Acenterlist">
          <ul class="AcenterUl">
            <div class="listTitle">申报状态</div>
            <li class="Acenterli" v-for="item in PolicyState" :key="item">
              {{ item.title }}
            </li>
          </ul>
        </div>

        <a-table
          :columnWidth="300"
          :dataSource="dataSource"
          :columns="AchievementsColumns"
          :row-class-name="
            (_record, index) => (index % 2 === 1 ? 'table-striped' : null)
          "
        />
      </div>
    </div>
    <CustomerService />
  </div>
</template>
<script>
import Head from "../components/Head";
import CustomerService from "../components/CustomerService";

import {
  AchievementsColumns,
  PolicyType,
  PolicyField,
  PolicyState,
  dataSource,
} from "../../data/db";

export default {
  //政策查询
  name: "PolicyMatching",
  data() {
    return {
      AchievementsColumns,
      PolicyType,
      PolicyField,
      PolicyState,
      dataSource,
    };
  },

  components: {
    Head,
    CustomerService,
  },
  methods: {
    onSearch() {
      console.log("adadad");
    },
  },
};
</script>
<style lang="less" scoped>
.PolicyMatching {
  width: 100%;
  height: 100vh;
  background: #fafafa;
}
.banner {
  position: relative;
}
.bannerInput {
  position: absolute;
  left: 720px;
  top: 50px;
  width: 500px;
  height: 400px;
}
.center {
  width: 1440px;
  margin: 0 auto;
}

.fenye {
  width: 1440px;
  margin: 0 auto;
}

.bar {
  width: 1440px;
  margin: 0 auto;
}
// :nth-child(5）
/deep/.ant-table-thead > tr > th {
  background: #0060dd;
}

/deep/ .ant-table-container table > thead > tr:nth-child(1) th:nth-child(1) {
  width: 300px;
}

/deep/.ant-table-container table > thead > tr:first-child th:last-child {
  width: 500px;
}
/deep/ .ant-table-pagination-right {
  justify-content: flex-start;
}

.Acenter {
  border-top: 2px solid #0060dd;
}
.Acenterlist {
  margin-top: 24px;
}

.AcenterUl {
  display: flex;
  padding-left: 0;
  // margin-bottom: 24px;
}
.Acenterli {
  list-style: none;
  border-right: 1px solid #b3b3b3;
  padding: 0 10px 0 10px;
}
.listTitle {
  color: #0060dd;
  font-size: 14px;
  font-weight: bold;
}
:deep(.table-striped) td {
  background-color: #fafafa;
  // color: red;
}
/deep/ .ant-table-cell {
  line-height: 20px;
}
/deep/ .ant-table-thead .ant-table-cell {
  line-height: 10px;
}
</style>