<template>
  <div>
    <Head />
   <Banner />
   <ZcNew />
   <Achievements />
   <Matching />
   <Expert />
   <Customer/>
   <Foot />
  </div>
</template>
<script>
import Head from "../components/Head";
import Customer from "../components/Customer";
import Expert from "../components/Expert";
import Matching from "../components/Matching";
import Achievements from "../components/Achievements";
import ZcNew from "../components/ZcNew";
import Banner from "../components/Banner";
import Foot from "../components/Foot";
export default {
  //首页
  name: "HomePage",
   components: {
    Banner,
    Head,
    Foot,
    Customer,
    Expert,
    Achievements,
    Matching,
    ZcNew
  }
};
</script>