<template>
  <div class="BigBackground">
    <Head />
    <div class="bar">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>通知公告</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="center">
        <a-row>
          <a-col :span="6">
            <div class="left">
              <div class="leftImg">
                <img src="../assets/Notice/1.png" alt="" />
              </div>
              <div class="leftTitle1">通知公告</div>
              <div class="leftTitle2">科技创新协同服务平台</div>
              <div class="leftTitle3">announcement</div>
            </div>
          </a-col>
          <a-col :span="18" class="right"> 
              <div class="rightCenter">

              <div class="rightTitle1">2020年企业上云典型案例遴选结果公示</div>
              <div class="rightTitle2">中华人民共和国工业和信息化部 <span>2021-06-01</span></div>
                <div class="rightTitle3">为贯彻落实《推动企业上云实施指南(2018-2020年)》(工信部信软(2018〕135号)，按照《工业和信息化部办公厅关于组织报送企业上云工作情况及典型案例征集工作的通知》(工行信发[2020]1023号)要求，经企业申报、地方推荐、形式审查和专家评审等环节--我们遴选出2020年企业上云典型案例，现予以公示。</div>              
                <div class="rightTitle4">
                    <div>公示时间:2021年5月28日-2021年6月3日</div>
                    <div>联系电话:010-68208201</div>
                    <div>传真:010-68208288</div>
                </div>
                <div>
                    <div class="rightTitle5">附件下载</div>
                    <div class="download"></div>
                </div>
                <div class="rightTitle5">原文链接</div>
                <div>https://www.miit.gov.cn/zwgk/wjgs/art/2021/art_a40f3eb17f3a4808</div>
              </div>
              
              
               </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../components/Head";

export default {
  //首页
  name: "Notice",
  components: {
    Head,
  },
};
</script>
<style lang="less" scoped>
.bar {
  width: 1440px;
  margin: 0 auto;
}
.center {
  margin-top: 22px;
}
.leftImg {
  margin-top: 28px;
    margin-left: 28px;

}
.leftTitle1 {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin: 13px 0 8px 28px;
}
.leftTitle2 {
  color: #797979;
  font-size: 12px;
    margin-left: 28px;

}
.BigBackground {
  background: #fafafa;
  height: 100vh;

}
.left {
  background: url(../assets/Notice/2.png) no-repeat;
  height: 500px;
  overflow: hidden;
}
.leftTitle3 {
  writing-mode: tb-rl;
  font-size: 50px;
  color: #f9f9f9;
  float: right;
  margin-right: 52px;
  margin-top: -78px;
  font-weight: bold;
}
.right {
  border-top: 2px solid #0060dd;
  background: #fff;
  height: 800px;
}
.rightTitle1{
    font-size: 20px;
    margin-top: 33px;
    color: #0060dd;
    font-weight: bold;
        margin-bottom: 15px;


}
.rightTitle2{
    font-size: 14px;
    color: #999999;
    margin-bottom: 57px;
}
.rightTitle3{
    font-size: 14px;
    color: #333333;
    margin-bottom: 30px;

}
.rightTitle4{
    margin-bottom: 70px;
}

.rightCenter{
    width: 870px;
    margin-left: 30px;
}
.rightTitle5{
    font-size: 14px;
    color: #333333;
    font-weight: bold;
    margin-bottom: 10px;
}
.download{
    width: 300px;
    height: 45px;
    background: chartreuse;
}
</style>