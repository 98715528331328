<template>
 <div>
   <!-- <Head />
   <Banner />
   <ZcNew />
   <Achievements />
   <Matching />
   <Expert />

   <Customer/>
   <Foot /> -->
   <router-view></router-view>
 </div>
</template>

<script>
// import Head from "./components/Head";
// import Customer from "./components/Customer";
// import Expert from "./components/Expert";
// import Matching from "./components/Matching";
// import Achievements from "./components/Achievements";
// import ZcNew from "./components/ZcNew";
// import Banner from "./components/Banner";

// import Foot from "./components/Foot";


export default {
  name: 'App',
  components: {
    // Banner,
    // Head,
    // Foot,
    // Customer,
    // Expert,
    // Achievements,
    // Matching,
    // ZcNew
  }
}
</script>

<style>

</style>
