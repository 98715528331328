<template>
  <div class="foot">
    <div class="footCenter">
      <div class="footLeft">
        <div class="footLeftTitle">科技创新协同服务平台</div>
        <div>
          <div class="footLeftUl">
            <div v-for="item of rotationCharts" :key="item.id">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div>
          <div class="footLeftUl1">
            <div>友情链接：</div>
            <div>能源界</div>
            <div>物联网</div>
            <div>招标信息</div>
            <div>成人分析</div>
            <div>人脸识别系统</div>
            <div>万云信息</div>
            <div>环保表示</div>
            <div>招标商业</div>
            <div>新知</div>
          </div>
        </div>
        <div class="beian">
          Copyright©All Rights Reserved 辽ICP备19010387号-2
        </div>
      </div>
      <div class="footRight">
        <a-row>
          <a-col :span="12">
            <div class="footRight1">
              <img src="../assets/shouye/2.png" alt="" />
              <div class="footTel">
                <p>客服电话</p>
                <p>11-011-5-2-5</p>
              </div>
            </div>
          </a-col>
          <div class="footRight2">
            <img src="../assets/shouye/3.png" alt="" />
            <div class="footRight2Title">
              <div>打开手机扫一扫</div>
              <div>进入小程序</div>
            </div>
          </div>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
// import {  } from "../../data/db";
import { rotationCharts } from "../../data/db";

export default {
  name: "App",
  data() {
    return {
      rotationCharts,
    };
  },
  components: {},
};
</script>

<style lang="less" scoped >
.foot {
  width: 100%;
  background: #343434;
}
.footCenter {
  height: 219px;
  width: 1440px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.footRight1 {
  width: 151px;
  height: 174px;
  margin-top: 21px;
  border-radius: 10px;
  // background: red;
  margin-right: 50px;
  overflow: hidden;
  img {
    margin-left: 12px;
    margin-top: 21px;
    width: 29px;
    height: 32px;
  }
  .footTel {
    margin-top: 15px;
    p {
      padding: 0;
      margin: 0;
      color: #fff;
    }
  }
}

.footRight2 {
  width: 128px;
  height: 174px;
  // background: red;
  margin-top: 21px;
  border-radius: 10px;
  vertical-align: middle;
  display: table-cell;
  text-align: center;
  img {
    width: 104px;
    height: 101px;
    text-align: center;
    margin-top: 21px;
  }
  .footRight2Title {
    div {
      color: #fff;
    }
  }
}
.footLeftUl {
  display: flex;
  margin-top: 68px;
  margin-bottom: 10px;
  // justify-content: center;
  div {
    list-style: none;
    margin-right: 10px;
    font-size: 12px;
    font-weight: bold;
    color: #ffffff;
    opacity: 0.5;
  }
}
.footLeftUl1 {
  margin-top: 10px;
  display: flex;
  margin-bottom: 10px;
  // justify-content: space-around;
  div {
    margin-right: 10px;
    font-size: 12px;
    font-weight: 400;
    color: #ffffff;
    opacity: 0.3;
  }
}
.footLeftTitle {
  font-size: 16px;
  font-weight: bold;
  color: #ffffff;
  // line-height: 138px;
  margin-top: 27px;
}
.beian {
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  opacity: 0.3;
}
</style>
