<template>
  <div class="achievements">
    <div class="achievementsCenter">
     

      <div>
        <a-row>
          <a-col :span="15" class="achievementsLeft">
             <div class="achievementsTitle">
        <img src="../assets/shouye/6.png" alt="" />
        政策新闻
      </div>
            <a-row> 
              <a-col :span="11">
               <NewList  title="政策法规" :data="newsNotice" />
              </a-col>
               <a-col class="aaa" :span="11">
               <NewList title="公告公示" :data="newsPublicity" />
              </a-col>
               <a-col :span="11" class="bottom">
               <NewList title="宏观政策" :data="newsMacroscopic" />
              </a-col>
               <a-col  class="aaa bottom" :span="11" >
               <NewList  title="区域政策" :data="newsRegion" />
              </a-col>
              
            </a-row>
          </a-col>

          <a-col :span="8" class="achievementsRight">
            <a-row>
              <a-col class="achievementsRightCenter" :span="12" v-for="item of newsNum" :key="item">
                <div class="rightCenter">
                  <div class="rightCenter1">
                    {{ item.num }}
                  </div>
                  <div class="rightCenter2">{{ item.title }}</div>
                </div>
              </a-col>
            </a-row>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import { newsNum,newsNotice ,newsPublicity ,newsMacroscopic,newsRegion} from "../../data/db";
import NewList from     "./NewList"
export default {
  name: "App",
  data() {
    return {
      newsNum,
      newsNotice,
      newsPublicity,
      newsMacroscopic,
      newsRegion
    };
  },
  components: {
      NewList
  },
};
</script>

<style lang="less" scoped>
.achievements {
  width: 100%;
  margin-bottom: 20px;
}
.achievementsCenter {
  width: 1440px;
  margin: 0 auto;
}
.achievementsTitle {
  font-size: 16px;
  font-weight: bold;
  color: #0060dd;
  img {
    width: 23px;
    height: 24px;
  }
}

.rightCenter {
  width: 169px;
  height: 150px;
  background: #f8f8f8;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
}
.rightCenter1 {
  font-size: 32px;
  font-weight: bold;
  color: #0060dd;
}
.rightCenter2 {
  font-size: 14px;
  font-weight: bold;
  color: #333333;
  margin-top: -40px;
}
.aaa{
  margin-left: 65px;
}
.bottom{
  margin-top: 20px;
}
.achievementsRightCenter{
            margin: 10px 0;

}
.ant-col-12{
  flex: 0 0 40%;

}
.achievementsRight{

  margin-left: 60px;
}
</style>
