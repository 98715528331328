export const rotationCharts = [
    {
        id: 1,
        title: "首页",
        path: "/"

    }
    ,
    {
        id: 2,
        title: "关于我们",
        path: "/"
    }
    ,
    {
        id: 3,
        title: "政策新闻",
        path: "PolicyNewList"
    }
    ,
    {
        id: 4,
        title: "院校成果",
        path: "/"
    }
    ,
    {
        id: 5,
        title: "政策匹配",
        path: "PolicyMatching"
    }
    ,
    {
        id: 6,
        title: "服务项目",
        path: "PolicyMatching"
    }
    ,
    {
        id: 7,
        title: "客户案例",
        path: "PolicyMatching"
    },
    {
        id: 8,
        title: "服务专家",
        path: "PolicyMatching"
    }
    , {
        id: 9,
        title: "项目管理",
        path: "PolicyMatching"
    },
    {
        id: 10,
        title: "联系我们",
        path: "PolicyMatching"
    }



]
export const customer = [

    {
        id: 1,
        url: require("/src/assets/shouye/4.png"),
    },
    {
        id: 2,
        url: require("/src/assets/shouye/5.png"),
    }


]
export const expert = [

    {
        id: 1,
        url: require("/src/assets/shouye/7.png"),
    },
    {
        id: 2,
        url: require("/src/assets/shouye/8.png"),
    }


]
export const xuexiaoAchievements = [

    {
        id: 1,
        title: "光纤传感技术在信息检测中的应用研究"
    },
    {
        id: 2,
        title: "多场耦合(电场、磁场)的生物阻抗测量技术及应用"
    },
    {
        id: 3,
        title: "多场耦合(电场、磁场)的生物阻抗测量技术及应用"
    },
    {
        id: 4,
        title: "多场耦合(电场、磁场)的生物阻抗测量技术及应用"
    },
    {
        id: 5,
        title: "多场耦合(电场、磁场)的生物阻抗测量技术及应用"
    }


]
export const remenAchievements = [

    {
        id: 1,
        title: "基于短时窄带模态分解的土木工程结构模态参数识别方法",
        url: require("/src/assets/shouye/10.png"),
        title1: "一种针对子母型基坑群改善侧移的支护的施工方法",
        school: "东北大学",
        time: "06/12"

    },
    {
        id: 2,
        title: "基于短时窄带模态分解的土木工程结构模态参数识别方法",
        url: require("/src/assets/shouye/10.png"),
        title1: "一种针对子母型基坑群改善侧移的支护的施工方法",
        school: "东北大学",
        time: "06/12"


    },
    {
        id: 3,
        title: "基于短时窄带模态分解的土木工程结构模态参数识别方法",
        url: require("/src/assets/shouye/10.png"),
        title1: "一种针对子母型基坑群改善侧移的支护的施工方法",
        school: "东北大学",
        time: "06/12"



    },
    {
        id: 4,
        title: "基于短时窄带模态分解的土木工程结构模态参数识别方法",
        url: require("/src/assets/shouye/10.png"),
        title1: "一种针对子母型基坑群改善侧移的支护的施工方法",
        school: "东北大学",
        time: "06/12"

    },


]
export const newsNum = [

    {
        id: 1,
        num: 10,
        title: "各类政策"
    },
    {
        id: 2,
        num: 10,
        title: "各类政策"
    },
    {
        id: 3,
        num: 10,
        title: "各类政策"
    },
    {
        id: 4,
        num: 10,
        title: "各类政策"
    },
    {
        id: 5,
        num: 10,
        title: "各类政策"
    },
    {
        id: 6,
        num: 10,
        title: "各类政策"
    }


]
export const newsPublicity = [

    {
        id: 1,
        title: "2020年度辽宁农业科技贡献奖受理项目公示",
        time: "10/10"

    },
    // {
    //     id: 2,
    //     title: "2020年度辽宁农业科技贡献奖受理项目公示",
    //     time: "10/10"

    // },
    // {
    //     id: 3,
    //     title: "2020年度辽宁农业科技贡献奖受理项目公示",
    //     time: "10/10"

    // },
    // {
    //     id: 4,
    //     title: "2020年度辽宁农业科技贡献奖受理项目公示",
    //     time: "10/10"

    // },
    // {
    //     id: 5,
    //     title: "2020年度辽宁农业科技贡献奖受理项目公示",
    //     time: "10/10"

    // }
    // , {
    //     id: 6,
    //     title: "2020年度辽宁农业科技贡献奖受理项目公示",
    //     time: "10/10"

    // },


]
export const newsNotice = [

    {
        id: 1,
        title: "关于开展省级企业技术中心2019年评aaaaaaaaaa价和名称调整工作的通知",
        time: "10/10"

    },
    // {
    //     id: 2,
    //     title: "关于开展省级企业技术中心2019年评价和名称调整工作的通知",
    //     time: "10/10"

    // },
    // {
    //     id: 3,
    //     title: "关于开展省级企业技术中心2019年评价和名称调整工作的通知",
    //     time: "10/10"

    // },
    // {
    //     id: 4,
    //     title: "关于开展省级企业技术中心2019年评价和名称调整工作的通知",
    //     time: "10/10"

    // },
    // {
    //     id: 5,
    //     title: "关于开展省级企业技术中心2019年评价和名称调整工作的通知",
    //     time: "10/10"

    // }
    // , {
    //     id: 6,
    //     title: "关于开展省级企业技术中心2019年评价和名称调整工作的通知",
    //     time: "10/10"

    // },


]



export const newsRegion = [

    {
        id: 1,
        title: "《关于提高全省上市公司质量的实施意见》政策解读...",
        time: "10/10"

    },
    // {
    //     id: 2,
    //     title: "《关于提高全省上市公司质量的实施意见》政策解读...",
    //     time: "10/10"

    // },
    // {
    //     id: 3,
    //     title: "《关于提高全省上市公司质量的实施意见》政策解读...",
    //     time: "10/10"

    // },
    // {
    //     id: 4,
    //     title: "《关于提高全省上市公司质量的实施意见》政策解读...",
    //     time: "10/10"

    // },
    // {
    //     id: 5,
    //     title: "《关于提高全省上市公司质量的实施意见》政策解读...",
    //     time: "10/10"

    // }
    // , {
    //     id: 6,
    //     title: "《关于提高全省上市公司质量的实施意见》政策解读...",
    //     time: "10/10"

    // },


]



export const newsMacroscopic = [

    {
        id: 1,
        title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
        time: "10/10"

    },
    // {
    //     id: 2,
    //     title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
    //     time: "10/10"

    // },
    // {
    //     id: 3,
    //     title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
    //     time: "10/10"

    // },
    // {
    //     id: 4,
    //     title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
    //     time: "10/10"

    // },
    // {
    //     id: 1,
    //     title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
    //     time: "10/10"

    // }
    // , {
    //     id: 5,
    //     title: "科技部教育部关于公布第十一批国家大学科技园认定结果的通...",
    //     time: "10/10"

    // },


]


export const banner = [

    {
        id: 1,
        url: require("/src/assets/shouye/11.png"),
        title: "政策查询",
        path: "/PolicyNewList"

    },
    {
        id: 2,
        url: require("/src/assets/shouye/12.png"),
        title: "成果查询",
        path: "Achievements"

    },
    {
        id: 3,
        url: require("/src/assets/shouye/13.png"),
        title: "智能匹配",
        path: "PolicyMatching"

    },
    {
        id: 4,
        url: require("/src/assets/shouye/14.png"),
        title: "高企评分",
        path: "Enterprise"

    }



]
export const bannerCenter = [

    {
        id: 1,
        title: "新政策消息",
        title1: " 您关注的行业有新政策",

    },
    {
        id: 2,
        title: "缴费提醒",
        title1: "   您的知识产权即将到期  ",
    },
    {
        id: 3,
        title: "缴费提醒",
        title1: "     您的知识产",
    },
    {
        id: 4,
        title: "新政策消息",
        title1: "您的知识产",
    }



]

export const PolicyMatchings = [

    {
        id: 1,
        title: "11",
        title1: " 沈阳市人22力资源和社会保障局",
        title2: "未启22动",
        title3: "辽宁22省",
        title4: "22",
    },
    {
        id: 2,
        title: "22",
        title1: " 沈阳市ww人力资源和社222会保障局",
        title2: "未ww启动",
        title3: "辽ww宁省",
        title4: "支持qq力度：获得首件国内授权发22明专利，单位资助5000元",
    },
    {
        id: 3,
        title: "33",
        title1: " 沈阳市asdf人力资源和社会保障局111111",
        title2: "未启adsa动",
        title3: "辽宁adad省",
        title4: "支持力度：获得首件国内adsad授权发明专利，单位资助5000元",
    },
    {
        id: 4,
        title: "33",
        title1: " 沈阳市asdf人力资源和社会保障局222222",
        title2: "未启adsa动",
        title3: "辽宁adad省",
        title4: "支持力度：获得首件国内adsad授权发明专利，单位资助5000元",
    },
    {
        id: 5,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    },
    {
        id: 6,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    },
    {
        id: 7,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    },
    {
        id: 8,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    },
    {
        id: 9,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    },
    {
        id: 10,
        title: "55",
        title1: " 沈阳市人力资源和社会adad保障局333",
        title2: "未启动",
        title3: "辽宁省",
        title4: "支持力度：获得首件国内授权发明专利，单位资助5000元",
    }



]
export const AchievementsColumns = [
    {
        title: "成果名称",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "发布机构",
        dataIndex: "release",
        key: "release",
    },
    {
        title: "领域 ",
        dataIndex: "field",
        key: "field",
    },
    {
        title: "内容",
        dataIndex: "type",
        key: "type",
    },
    {
        title: "简介",
        dataIndex: "introduction",
        key: "introduction",
    },
]
export const dataSource = [
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },
    {
        key: "1",
        name: "国密键盘第四代信息交互终端.docx",
        release: "东北大学",
        field: "制造业",
        type: "实用新型专利",
        introduction:
            "本项目研发的国密键盘第四代信息交互终端设有终端壳体， 电磁感应笔，固定在终端壳体内的主控板定在终端壳......",
    },

]


export const PolicyType = [
    {
        title: "论文喝专著"
    },
    {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    },
    {
        title: "论文喝专著"
    },
    {
        title: "论文喝专著"
    },
    {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    },
]



export const PolicyField = [
    {
        title: "论文wwqq喝专著"
    },
    {
        title: "论文喝ww专著"
    }, {
        title: "论文喝ww专著"
    },
    {
        title: "论dd文喝专著"
    },
    {
        title: "论文喝aa专著"
    },
    {
        title: "论文喝sds专著"
    }, {
        title: "论文喝sdsd专著"
    }, {
        title: "论文喝专著"
    }, {
        title: "论文rr喝专著"
    },
]




export const PolicyState = [
    {
        title: "论文喝tt专著"
    },
    {
        title: "论文喝专yy著"
    }, {
        title: "论文喝uu专著"
    },
    {
        title: "论文喝专ii著"
    },
    {
        title: "论文喝专著"
    },
    {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    }, {
        title: "论文喝专著"
    }
]
export const PolicySearch1 = [
    {
        title: "国家级"
    },
    {
        title: "省级"
    }, {
        title: "市级"
    },
    {
        title: "区县级"
    },
]

export const PolicySearch2 = [
    {
        title: "科技成果转化"
    },
    {
        title: "知识产权"
    }, {
        title: "资质认定"
    },
    {
        title: "财税扶持"
    },
    {
        title: "税务优惠"
    },
    {
        title: "人才政策"
    }, {
        title: "科技创新"
    }, {
        title: "资金补贴"
    }, {
        title: "商业发展"
    },
    {
        title: "创业扶持"
    },
    {
        title: "融资信贷"
    }
]

export const PolicySearch3 = [
    {
        title: "电子信息"
    },
    {
        title: "互联网"
    }, {
        title: "航空航天"
    },
    {
        title: "海洋产业"
    },
    {
        title: "新能源汽车"
    },
    {
        title: "医疗器械"
    }, {
        title: "生物医药"
    }, {
        title: "生命健康"
    }, {
        title: "机器人"
    }
    , {
        title: "高级装备"
    }
    , {
        title: "节能环保"
    }
    , {
        title: "生态修复"
    }
]

export const PolicyFormsContentTitle = [
    {
        title: "名称",
        dataIndex: "name",
        key: "name",
    },
    {
        title: "信息来源",
        dataIndex: "release",
        key: "release",
    },
    {
        title: "地区 ",
        dataIndex: "field",
        key: "field",
    },
    {
        title: "申报状态",
        dataIndex: "type",
        key: "type",
    },
    {
        title: "申报条件",
        dataIndex: "introduction",
        key: "introduction",
    },
]

export const PolicyFormsContent = [
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },
    {
        key: "1",
        name: "经济发展贡献奖励",
        release: "法库县商务局",
        field: "法库县",
        type: "未开始",
        introduction:"在法库县注册具有独立法人的企业。",
    },

]