<template>
  <div class="BigBackground">
    <Head />
    <div class="bar">
      <a-breadcrumb>
        <a-breadcrumb-item><a href="/">首页</a></a-breadcrumb-item>
        <a-breadcrumb-item>
          <a href="Achievements">成果列表</a>
        </a-breadcrumb-item>
        <a-breadcrumb-item>通知公告</a-breadcrumb-item>
      </a-breadcrumb>
      <div class="center">
        <a-row>
          <a-col :span="6">
            <div class="left">
              <div class="leftImg">
                <img src="../assets/AchieveDetitle/6.png" alt="" />
              </div>
              <div class="leftTitle2">科技创新协同服务平台</div>
              <div class="leftTitle3">announcement</div>
            </div>
          </a-col>
          <a-col :span="18" class="right">
            <div class="rightCenter">
              <div class="rightTitle3">
                <div class="rightTitle1">国密键盘第四代信息交互终端</div>
                <div>
                  <span class="rightTitle2">发布机构</span>：<span
                    >东北大学</span
                  >
                </div>
                <div>
                  <span class="rightTitle2">发布时间</span>：<span
                    >2022-1-1</span
                  >
                </div>
                <div>
                  <span class="rightTitle2">成果类型</span>：<span
                    >实用新型专利</span
                  >
                </div>
                <div>
                  <span class="rightTitle2">应用类型</span>：<span
                    >航空航天 机械制造</span
                  >
                </div>
              </div>
              <div class="rightBigButton">
                <div class="rightButton1">添加关注</div>
                <div class="rightButton2">联系机构</div>
              </div>

              <a-button type="primary" @click="showModal">我要申报</a-button>
              <a-modal
                v-model:visible="visible"
                title="我要申报"
                @ok="handleOk"
              >
                <div>公司名称</div>
                <div>
                  <a-input v-model:value="value" placeholder="请输入公司名称" />
                </div>
                <div>所在城市</div>
                <div>
                  <a-input v-model:value="value" placeholder="请输入所在城市" />
                </div>
                <div>联系人</div>
                <div>
                  <a-input v-model:value="value" placeholder="请输入联系姓名" />
                </div>
                <div>联系方式</div>
                <div>
                  <a-input v-model:value="value" placeholder="请输入联系方式" />
                </div>
              </a-modal>
              <div></div>

              <div>
                <div class="rightTitle4">成果简介</div>
                <div class="rightTitle5">
                  本项目研发的国密键盘第四代信息交互终端设有终端壳体，电磁感应笔，固定在终端壳体内的主控板，以及固定在终端光体上并与主控板电连接的实体按键、
                  通讯装置、摄像头、麦克风、指纹仪和屏幕;主控板通过通讯装置与主机通讯。
                </div>
              </div>
              <div>
                <div class="rightTitle4">成果简介</div>
                <div>
                  本项目研发的国密键盘第四代信息交互终端设有终端壳体，电磁感应笔，固定在终端壳体内的主控板，以及固定在终端光体上并与主控板电连接的实体按键、
                  通讯装置、摄像头、麦克风、指纹仪和屏幕;主控板通过通讯装置与主机通讯。
                </div>
                <div class="rightTitle6">
                  稳定性主要表现在软件无论运行模式下，都必须稳定且快速的接收分部对软件传送的若干指。
                </div>
                <div class="rightTitle5">
                  稳定性主要表现在软件无论运行模式下，都必须稳定且快速的接收分部对软件传送的若干指。
                </div>
              </div>
              <div>
                <div class="rightTitle4">附件下载</div>
                <div>附件</div>
              </div>
            </div>
          </a-col>
        </a-row>
      </div>
    </div>
  </div>
</template>
<script>
import Head from "../components/Head";

export default {
  data() {
    return {
      visible: false,
    };
  },
  name: "AchieveDetitle",
  components: {
    Head,
  },
  methods: {
    showModal(a) {
      this.visible = true;

      this.shipin = a;
      console.log("abcccccc", this.shipin);
    },
    handleOk(e) {
      this.visible = false;
    },
  },
};
</script>
<style lang="less" scoped>
.bar {
  width: 1440px;
  margin: 0 auto;
}
.center {
  margin-top: 22px;
}
.leftImg {
  margin-top: 28px;
  margin-left: 28px;
}
.leftTitle1 {
  color: #000;
  font-weight: bold;
  font-size: 20px;
  margin: 13px 0 8px 28px;
}
.leftTitle2 {
  color: #797979;
  font-size: 12px;
  margin-left: 28px;
}
.BigBackground {
  background: #fafafa;
  height: 100vh;
}
.left {
  background: url(../assets/Notice/2.png) no-repeat;
  height: 500px;
  overflow: hidden;
}
.leftTitle3 {
  writing-mode: tb-rl;
  font-size: 50px;
  color: #f9f9f9;
  float: right;
  margin-right: 52px;
  margin-top: -78px;
  font-weight: bold;
}
.right {
  border-top: 2px solid #0060dd;
  background: #fff;
  height: 800px;
  overflow: hidden;
  //   margin-left: 20px;
}
.rightBigButton {
  display: flex;
  margin-bottom: 60px;
}
.rightButton1 {
  width: 115px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background: #19d5b9;
}
.rightButton2 {
  width: 115px;
  height: 35px;
  line-height: 35px;
  text-align: center;
  color: #fff;
  border-radius: 5px;
  background: #1a91fb;
  margin-left: 10px;
}
.rightCenter {
  margin-left: 25px;
}
.rightTitle1 {
  margin-top: 25px;

  font-size: 22;
  font-weight: bold;
  color: #0060dd;
  margin-bottom: 25px;
}
.rightTitle2 {
  color: #999;
}
.rightTitle3 {
  margin-bottom: 25px;
}
.rightTitle4 {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 22px;
}
.rightTitle5 {
  margin-bottom: 35px;
}
.rightTitle6 {
  margin-bottom: 20px;
  margin-top: 20px;
}
</style>