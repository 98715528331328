<template>
 
    <div class="customerService">
      <img src="../assets/policyMatching/3.png" alt="" />
      <div class="contact">
        联系客服
      </div>
    </div>
</template>
<script>


export default {
  name: "PolicyMatching",
  data() {
    return {
      
    };
  },

  components: {
  },
  methods: {
  
  }
};
</script>
<style lang="less" scoped>

.customerService{
      position: absolute;
      left: 1700px;
      top: 750px;

}
.contact{
  text-align: center;
  line-height: 35px;
  width: 105px;
  height: 35px;
  background: #19d5b9;
  border: 1px solid #a0a0a0;
  border-radius: 5px;
  margin-left: -14px;
}
</style>